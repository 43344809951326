import React from 'react';
import styled from 'styled-components';
import {Line} from '@styled-icons/fa-brands/Line';
import {LOGIN_TYPE} from 'rev.sdk.js/Components/LoginForm';
import * as User from 'rev.sdk.js/Actions/User';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

function PreSocialLogin(props) {
  const [user] = useOutlet('user');

  React.useEffect(() => {
    if (user) {
      const {store} = qs.parse(window.location.search);
      AppActions.navigate(`/store/products/?store=${store}`);
    }
  }, [user]);

  const onSocialProviderLogin = async (type) => {
    try {
      switch (type) {
        case LOGIN_TYPE.GOOGLE:
          User.googleRedirect();
          break;
        case LOGIN_TYPE.FACEBOOK:
          User.facebookRedirect();
          break;
        case LOGIN_TYPE.LINE:
          User.lineRedirect();
          break;
        default:
          break;
      }
    } catch (err) {
      console.warn('login form error:', err);
    }
  };

  return (
    <Wrapper>
      <div className="main-content">
        <h2>登入</h2>
        <LineBtn onClick={() => onSocialProviderLogin(LOGIN_TYPE.LINE)} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .main-content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    & h2 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 200;
      line-height: 1.5;
      margin: 8px;
    }
  }
`;

const LineBtn = styled(Line).attrs({
  size: 80,
  color: '#02C755',
})`
  margin: 0px 10px;
  cursor: pointer;
`;

export default PreSocialLogin;
